import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import SimpleReactLightbox from "simple-react-lightbox"
import GlobalStyle from "../components/global-style"
import Header from "../components/header"
import Footer from "../components/footer"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)

  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <SimpleReactLightbox>
        <GlobalStyle />

        <div className="global-wrapper" data-is-root-path={isHomePage}>
          <Header />

          <main>{children}</main>

          <Footer />
        </div>
      </SimpleReactLightbox>
    </SEOContext.Provider>
  )
}

export default Layout
