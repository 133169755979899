import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { FaLinkedinIn, FaYoutube, FaInstagram, FaTwitter } from "react-icons/fa"

const FooterStyles = styled.footer`
  .newsletter {
    padding: 50px 10%;
    text-align: center;

    h3 {
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 1.4em;
      color: var(--grey);
    }

    ul {
      margin: 30px 0;

      li {
        display: inline-block;
        margin: 0 4px;

        a {
          display: block;
          border-radius: 3px;
          width: 32px;
          height: 32px;
          background-color: var(--blue);
          transition: background-color 0.3s ease;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: var(--grey);
          }
        }
      }
    }

    form {
      margin: 0 auto;
      display: flex;

      input {
        flex: 1 1 72%;
        margin-right: 3%;
        padding: 25px;
        font-size: 1.4rem;
        border: 2px solid var(--lgrey);
      }

      button {
        color: #fff;
        border: 0;
        border-radius: 0;
        font-weight: 700;
        background-color: var(--blue);
        padding: 27px 20px;
        flex: 1 1 25%;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: var(--grey);
        }
      }
    }
  }

  .widgets {
    max-width: 100%;
    margin: 50px 0 0;
    font-size: 1.8rem;
    padding: 0 5%;

    h3 {
      font-weight: 700;
      font-size: 0.8vw;
      margin-bottom: 4rem;
      margin-top: 0;
    }

    ul {
      line-height: 1.3;

      li {
        margin-bottom: 1.4rem;

        a {
          font-weight: 500;
        }
      }
    }

    .contact {
      background-color: var(--grey);
      padding: 40px;
      color: #fff;

      p {
        line-height: 2;

        a {
          color: #fff;
          font-weight: 500;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .bottom {
    background-color: var(--dgrey);
    padding: 40px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copy {
      font-size: 1.3rem;
      color: var(--lgrey);
    }

    a {
      font-weight: 500;

      &:hover {
        color: #fff;
      }
    }

    ul {
      font-size: 1.8rem;

      li {
        &:before {
          content: "|";
          display: inline-block;
          margin: 0 5px;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .widgets {
      .row {
        display: block;
        text-align: center;
      }

      h3 {
        margin-top: 4rem;
        font-size: 1.8rem;
      }

      .contact {
        margin-top: 4rem;

        h3 {
          margin-top: 0;
        }
      }
    }

    .bottom {
      display: block;
      text-align: center;

      ul {
        margin-top: 3rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .newsletter {
      form {
        display: block;

        input,
        button {
          display: block;
          width: 100%;
        }

        input {
          margin-bottom: 1rem;
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allWpMenu {
        nodes {
          id
          name
          menuItems {
            nodes {
              childItems {
                nodes {
                  id
                  label
                  path
                }
              }
              id
              label
              parentId
              path
            }
          }
        }
      }
      allWp {
        nodes {
          options {
            acfOptions {
              contact {
                content
                title
              }
              social {
                networks {
                  icon
                  link
                }
                title
              }
            }
          }
        }
      }
    }
  `)

  const options = { ...data.allWp.nodes[0].options.acfOptions }

  const social = {
    LinkedIn: FaLinkedinIn,
    YouTube: FaYoutube,
    Instagram: FaInstagram,
    Twitter: FaTwitter,
  }

  const [state, setState] = useState({
    message: "",
    status: "",
    email: "",
  })

  const handleChange = event => {
    setState({
      email: event.target.value,
      message: "",
      status: "",
    })
  }

  const handleSubscription = event => {
    event.preventDefault()

    const xhr = new XMLHttpRequest()
    const form = event.target
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      const { status, message } = JSON.parse(xhr.response)
      if (xhr.status === 200 && status === 201) {
        form.reset()
        setState({
          message: "Thank you for subscribing",
          status: status,
          email: state.email,
        })
      } else {
        setState({ message: message, status: status, email: state.email })
      }
    }
    xhr.send(JSON.stringify({ email: state.email }))
  }

  return (
    <FooterStyles>
      <div className="newsletter container">
        {options.social.title ? <h3>{options.social.title}</h3> : null}

        {options.social.networks.length ? (
          <ul className="list-unstyled list-inline">
            {options.social.networks.map(network => {
              return (
                <li>
                  <a href={network.link} target="_blank" rel="noreferrer">
                    {React.createElement(social[network.icon], null, null)}
                  </a>
                </li>
              )
            })}
          </ul>
        ) : null}
        <form
          method="post"
          action={`${process.env.GATSBY_BASE_URL}/wp-json/newsletter/v1/signup`}
          onSubmit={handleSubscription}
        >
          <input
            type="text"
            placeholder="Email"
            required
            onChange={handleChange}
          />
          <button>Subscribe</button>
        </form>
        {state.message ? <p className="response">{state.message}</p> : null}
      </div>
      <div className="widgets container container-full">
        <div className="row">
          <div className="col">
            {data &&
              data.allWpMenu &&
              data.allWpMenu.nodes.length &&
              data.allWpMenu.nodes.map(menu => {
                return (
                  <React.Fragment key={menu.id}>
                    {menu.name === "About Byers" ? (
                      <nav>
                        <h3>{menu.name}</h3>
                        <ul>
                          {menu.menuItems &&
                            menu.menuItems.nodes.length &&
                            menu.menuItems.nodes.map(aboutMenu => {
                              return (
                                <li key={aboutMenu.id}>
                                  <Link to={aboutMenu.path}>
                                    {aboutMenu.label}
                                  </Link>
                                </li>
                              )
                            })}
                        </ul>
                      </nav>
                    ) : null}
                  </React.Fragment>
                )
              })}
          </div>
          <div className="col">
            {data &&
              data.allWpMenu &&
              data.allWpMenu.nodes.length &&
              data.allWpMenu.nodes.map(menu => {
                return (
                  <React.Fragment key={menu.id}>
                    {menu.name === "Solutions" ? (
                      <nav>
                        <h3>{menu.name}</h3>
                        <ul>
                          {menu.menuItems &&
                            menu.menuItems.nodes.length &&
                            menu.menuItems.nodes.map(solutionsMenu => {
                              return (
                                <li key={solutionsMenu.id}>
                                  <Link to={solutionsMenu.path}>
                                    {solutionsMenu.label}
                                  </Link>
                                </li>
                              )
                            })}
                        </ul>
                      </nav>
                    ) : null}
                  </React.Fragment>
                )
              })}
          </div>
          <div className="col">
            {data &&
              data.allWpMenu &&
              data.allWpMenu.nodes.length &&
              data.allWpMenu.nodes.map(menu => {
                return (
                  <React.Fragment key={menu.id}>
                    {menu.name === "Resources" ? (
                      <nav>
                        <h3>{menu.name}</h3>
                        <ul>
                          {menu.menuItems &&
                            menu.menuItems.nodes.length &&
                            menu.menuItems.nodes.map(resourcesMenu => {
                              return (
                                <li key={resourcesMenu.id}>
                                  <Link to={resourcesMenu.path}>
                                    {resourcesMenu.label}
                                  </Link>
                                </li>
                              )
                            })}
                        </ul>
                      </nav>
                    ) : null}
                  </React.Fragment>
                )
              })}
          </div>
          <div className="col">
            <div className="contact">
              {options.contact.title ? <h3>{options.contact.title}</h3> : null}

              {options.contact.content ? (
                <div
                  dangerouslySetInnerHTML={{ __html: options.contact.content }}
                ></div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="copy">
          Copyright © {new Date().getFullYear()}. Byers Scientific. All rights
          reserved. Designed by{" "}
          <a href="https://savyagency.com" target="_blank" rel="noreferrer">
            Savy Agency
          </a>
          .
        </div>
        {data &&
          data.allWpMenu &&
          data.allWpMenu.nodes.length &&
          data.allWpMenu.nodes.map(menu => {
            return (
              <React.Fragment key={menu.id}>
                {menu.name === "Footer" ? (
                  <nav>
                    <ul className="list-unstyled list-inline">
                      {menu.menuItems &&
                        menu.menuItems.nodes.length &&
                        menu.menuItems.nodes.map(footerMenu => {
                          return (
                            <li key={footerMenu.id}>
                              <Link to={footerMenu.path}>
                                {footerMenu.label}
                              </Link>
                            </li>
                          )
                        })}
                    </ul>
                  </nav>
                ) : null}
              </React.Fragment>
            )
          })}
      </div>
    </FooterStyles>
  )
}

export default Footer
