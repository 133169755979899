import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Logo from "../svg/logo.svg"
import Lock from "../svg/lock.svg"
import styled from "styled-components"
import { FaChevronDown, FaBars } from "react-icons/fa"

const HeaderStyles = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 5%;
  box-shadow: 0 1px 0 rgb(0 0 0 / 10%);

  .logo {
    flex: 1;
    max-width: 210px;
  }

  nav {
    flex: 1;
    text-align: right;

    ul {
      font-size: 1.6rem;
      text-transform: uppercase;

      li {
        position: relative;
        padding-right: 22px;
        padding-bottom: 43px;

        a {
          color: var(--nav-trans);

          &.active,
          &:hover {
            font-weight: 500;
            color: var(--blue);
          }
        }

        svg {
          fill: var(--nav-trans);
          width: 9px;
          margin: 0 0 -2px 6px;
        }

        ul {
          display: none;
          opacity: 0;
          position: absolute;
          top: 61px;
          left: 0;
          min-width: 255px;
          transition: all 0.2s ease-in-out;
          text-align: left;
          border-top: 3px solid var(--blue);
          padding: 20px 0;
          z-index: 9999;
          width: 240px;
          background: #fff;
          box-shadow: 0 2px 5px rgb(0 0 0 / 10%);

          li {
            padding: 0 20px;
            margin: 0;
            line-height: 28px;

            a {
              display: block;
              text-transform: none;
              border-bottom: 1px solid var(--bd);
              font-weight: 500;
              padding: 6px 20px;
            }

            &:last-child {
              a {
                border: 0;
              }
            }
          }
        }

        &:hover {
          ul {
            opacity: 1;
            display: block;
          }
        }

        &.client-portal {
          padding: 0;
          transform: translateY(-32px);
          display: inline-block;
          font-size: 1.4rem;

          a {
            background: var(--blue);
            border-radius: 0 0 3px 3px;
            color: #fff;
            padding: 1.4rem 1.4rem 1.4rem 1rem;
            font-weight: 600;
            text-transform: none;
            transition: background 0.3s ease;

            svg {
              width: 22px;
              margin: 0 5px -5px -2px;
            }

            &:hover {
              background: var(--dblue);
            }
          }
        }
      }
    }

    .mobile-nav {
      display: none;
      border: 0;
      background: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      float: right;
      outline: 0;

      svg {
        display: block;
        fill: var(--blue);
        width: 25px;
        height: 25px;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    nav {
      .mobile-nav {
        display: block;
      }

      ul {
        position: absolute;
        top: 105px;
        left: 0;
        width: calc(100% - 60px);
        text-align: left;
        opacity: 1;
        z-index: 9999;
        border-top: 3px solid var(--blue);
        box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
        background: #fff;
        margin-left: 30px;
        margin-right: 30px;
        padding: 30px;
        box-sizing: border-box;
        transform: scaleY(0);
        transform-origin: top center;
        transition: transform 0.3s ease;

        li {
          display: block;
          padding: 0;

          svg {
            display: none;
          }

          a {
            border-bottom: 1px solid rgba(0, 0, 0, 0.03);
            color: var(--nav);
            padding: 14px 30px;
            display: block;
            font-size: 1.4rem;
            text-transform: none;
            font-weight: 600;

            &:hover,
            &.active {
              color: var(--nav);
              font-weight: 600;
              background-color: rgba(0, 0, 0, 0.03);
              opacity: 0.7;
            }
          }

          &.client-portal {
            width: 100%;
            transform: none;

            svg {
              display: inline-block;
            }

            a {
              &:hover {
                opacity: 1;
                color: #fff;
                background: var(--dblue);
              }
            }
          }

          &.dropdown {
            & > a {
              font-weight: 700;
              background-color: rgba(0, 0, 0, 0.03);
            }
          }

          ul {
            display: block;
            transform: scaleY(1);
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            box-shadow: none;
            border: 0;
            opacity: 1;
            margin: 0;
            padding: 0;

            li {
              padding-left: 30px;

              a {
                font-weight: 600;
                border-bottom: 1px solid rgba(0, 0, 0, 0.03);
              }
            }
          }
        }

        &.active {
          transform: scaleY(1);
        }
      }
    }
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      wpMenu(name: { eq: "Top" }) {
        id
        menuItems {
          nodes {
            childItems {
              nodes {
                id
                label
                path
              }
            }
            id
            label
            parentId
            path
            cssClasses
          }
        }
      }
    }
  `)

  const [mobileNav, setMobileNav] = useState("")

  const handleMobileNav = () => {
    const nav = mobileNav ? "" : "active"
    setMobileNav(nav)
  }

  return (
    <HeaderStyles>
      <div className="logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {data && data.wpMenu && data.wpMenu.menuItems.nodes.length ? (
        <nav>
          <button
            className="mobile-nav"
            onClick={handleMobileNav}
            aria-label="Mobile Navigation"
          >
            <FaBars />
          </button>
          <ul className={`list-inline ${mobileNav}`}>
            {data.wpMenu.menuItems.nodes.map(topMenu => {
              const cssClasses = topMenu.cssClasses.join(" ")
              return (
                <React.Fragment key={topMenu.id}>
                  {!topMenu.parentId ? (
                    <li
                      className={`${cssClasses} ${
                        topMenu.childItems && topMenu.childItems.nodes.length
                          ? "dropdown"
                          : ""
                      }`}
                    >
                      <Link to={topMenu.path} activeClassName="active">
                        {cssClasses.includes("client-portal") ? <Lock /> : null}
                        {topMenu.label}
                      </Link>
                      {topMenu.childItems && topMenu.childItems.nodes.length ? (
                        <>
                          <FaChevronDown />
                          <ul className="list-block">
                            {topMenu.childItems.nodes.map(childMenu => {
                              return (
                                <li key={childMenu.id}>
                                  <Link
                                    to={childMenu.path}
                                    activeClassName="active"
                                  >
                                    {childMenu.label}
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        </>
                      ) : null}
                    </li>
                  ) : null}
                </React.Fragment>
              )
            })}
          </ul>
        </nav>
      ) : null}
    </HeaderStyles>
  )
}

export default Header
