import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  // variables
  :root {
    --blue: #679ab7;
    --dblue: #728fa3;
    --lblue: #2ea3f2;
    --body: #444444;
    --heading: #333333;
    --grey: #485561;
    --lgrey: #a0a0a0;
    --dgrey: #282828;
    --nav: #666666;
    --bd: #e7e7e7;
    --tg: #dee5ea;
    --form: #77818a;
    --black: #011323;
    --formbd: rgba(72, 85 ,97, 0.74);
    --trans: rgba(0, 0, 0, 0.03);
    --nav-trans: rgba(0, 0, 0, 0.6);
    --mont: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    --col: 25px;
    --mar: 2rem 0;
  }

  // typography
  html {
    font-size: 62.5%;
  }

  body {
    color: var(--body);
    font-family: var(--mont);
    font-size: 1.6rem;
  }

  a {
    color: var(--blue);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--grey);
    }
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: normal;
    font-weight: 500;
    display: block;
    margin: var(--mar);
  }

  h1, .h1 {
    font-size: 4.5rem;
  }

  h2, .h2 {
    font-size: 3rem;
  }

  h3, .h3 {
    font-size: 2.5rem;
  }

  h4, .h4 {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .sub-title {
    text-transform: uppercase;
    color: var(--blue);
    font-weight: 700;
    letter-spacing: 4px;
    font-size: 1.6rem;
  }

  nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  p, ul, ol {
    margin: var(--mar);
  }

  ul, ol {
    &.list-unstyled {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &.list-inline {
      li {
        display: inline;
      }
    }

    &.list-block {
      li {
        display: block;
      }
    }
  }

  // layout
  .container {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 10%;

    &.container-full {
      max-width: 100%;
    }

    .row {
      display: flex;
      flex-wrap: wrap;

      .col {
        flex: 1;
      }
    }
  }

  // buttons
  .btn,
  .gform_button {
    display: inline-block;
    margin: var(--mar);
    padding: 1.5rem 2rem;
    background: var(--blue);
    color: #fff;
    border: 1px solid var(--blue);
    border-radius: 5px;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    outline: 0;
    box-sizing: border-box;
    transition: color ease 0.3s, background ease 0.3s, border-color ease 0.3s;

    &:hover {
      background: var(--grey);
      color: #fff;
      border-color: var(--grey);
    }

    &.btn-light {
      background: none;
      color: #fff;
      border: 1px solid #fff;

      &:hover {
        background: var(--blue);
        border-color: var(--blue);
      }
    }
  }

  // forms
  form {
    input,
    select,
    textarea {
      border-radius: 0;
      color: var(--body);
      outline: 0;
      box-sizing: border-box;
    }

    &.gravityform {
      .gform_fields {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
      }

      .hidden-label {
        label {
          display: none;
        }
      }

      .gfield {
        flex: 1 1 100%;
      }

      .gf_left_half {
        margin-right: 0.75rem;
      }

      .gf_right_half {
        margin-left: 0.75rem;
      }

      .gf_left_half,
      .gf_right_half {
        flex: 1 1 calc(50% - 0.75rem);

        input {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .gform_footer {
        text-align: right;
      }

      .gravityform__error_message {
        color: #ff0000;
        margin-bottom: 1.5rem;
      }

      .validation_error {
        color: #ff0000;

        p {
          margin-top: 0;
        }
      }

      input,
      select,
      textarea {
        width: 100%;
        padding: 16px;
        color: var(--form);
        border: 1px solid var(--formbd);
        height: 54px;
        margin-bottom: 1.5rem;
      }

      textarea {
        height: 150px;
      }

      .button {
        margin-bottom: 0;
      }
    }
  }

  .gform_confirmation_message {
    text-align: center;
  }

  // video
  .video-wrap {
    padding: 56.25% 0 0 0;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  // image
  img {
    max-width: 100%;
    height: auto;
  }

  .gatsby-image-wrapper {
    /* padding-top: 100%; */
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  .background-image-gradient {
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(
        219deg,
        rgba(10, 10, 10, 0) 0%,
        #0a0101 100%
      );
    }
  }

  .background-image-overlay {
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(53,53,53,0.57);
    }
  }

  // slider
  .slider {
    .swiper-container {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: hidden;
      list-style: none;
      padding: 0;
      z-index: 1;
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: calc(50% - 25px);
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      background-color: var(--lgrey);
      border-radius: 100%;
      transition: opacity 0.3s ease;

      svg {
        width: 25px;
        height: 25px;
        color: var(--dblue);
      }

      &:hover {
        opacity: 0.85;
      }
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        opacity: 0.5;
      }
    }

    .swiper-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      box-sizing: content-box;
    }

    .swiper-slide {
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      position: relative;
      transition-property: transform;

      img {
        display: block;
      }
    }
  }

  // responsive
  @media screen and (max-width: 1080px) {
    .container {
      max-width: 100%;

      .row {
        .col {
          flex: 1 1 100%;
        }
      }
    }
  }
`

export default GlobalStyle
